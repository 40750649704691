.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6.4rem;
}

.skills {
  width: 95%;
  max-width: var(--container-width);
}

.skills__grid {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  gap: 2.4rem;
}

.skills__group {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--color-black);
  padding: 1.2rem;
}

.skills-group__text {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.skills-group__text span {
  font-size: var(--font-xs);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: var(--color-gray);
}

.skills-group__text h3 {
  font-size: var(--font-lg);
  font-weight: 700;
  letter-spacing: -1px;
  color: var(--color-white);
  margin-left: -0.1rem;
}

.skills-group__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
}

.skills__item {
  display: flex;
  position: relative;
  z-index: 0;
  aspect-ratio: 1/1;
  background-color: var(--color-black-variant);
  padding: 0.8rem;
  border-radius: 1rem;
  overflow: hidden;
  user-select: none;
}

.skills-item__gradient {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: var(--gradient-react);
  clip-path: ellipse(0 0 at 0 0);
  transition: all 0.5s ease-out;
}

.skills__item:hover .skills-item__gradient {
  clip-path: ellipse(150% 150% at 0 0);
}

.skills-item__text {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  z-index: 1;
}

.skills-item__text span {
  font-size: var(--font-xs);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: var(--color-gray);
  transition: all 0.2s ease-out;
}

.skills__item:hover .skills-item__text span {
  color: var(--color-white);
}

.skills-item__text h3 {
  font-size: var(--font-md);
  font-weight: 500;
  letter-spacing: -1px;
  color: var(--color-white);
  margin-left: -0.1rem;
  transition: all 0.4s ease-out;
}

.skills-item__level {
  position: absolute;
  bottom: 0.8rem;
  left: 0.8rem;
  z-index: 1;
  font-size: var(--font-xs);
  font-weight: 400;
  color: var(--color-gray);
  transition: all 0.2s ease-out;
}

.skills__item:hover .skills-item__level {
  color: var(--color-white);
}

.skills-item__icon {
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
  z-index: 1;
  width: 26px;
  height: 26px;
  fill: var(--color-white);
  transition: all 0.2s ease-out;
}

.skills-item__icon path,
.skills-item__icon polygon,
.skills-item__icon rect {
  transition: all 0.2s ease-out !important;
}

.skills__item.nextjs .skills-item__gradient {
  background: var(--gradient-nextjs);
}
.skills__item.react .skills-item__gradient {
  background: var(--gradient-react);
}
.skills__item.react-native .skills-item__gradient {
  background: var(--gradient-react-native);
}
.skills__item.javascript .skills-item__gradient {
  background: var(--gradient-javascript);
}
.skills__item.html .skills-item__gradient {
  background: var(--gradient-html);
}
.skills__item.css .skills-item__gradient {
  background: var(--gradient-css);
}
.skills__item.tailwind .skills-item__gradient {
  background: var(--gradient-tailwind);
}
.skills__item.nodejs .skills-item__gradient {
  background: var(--gradient-nodejs);
}
.skills__item.express .skills-item__gradient {
  background: var(--gradient-express);
}
.skills__item.python .skills-item__gradient {
  background: var(--gradient-python);
}
.skills__item.mongodb .skills-item__gradient {
  background: var(--gradient-mongodb);
}
.skills__item.firebase .skills-item__gradient {
  background: var(--gradient-firebase);
}
.skills__item.jwt .skills-item__gradient {
  background: var(--gradient-jwt);
}
.skills__item.aws .skills-item__gradient {
  background: var(--gradient-aws);
}

.skills__item.react .skills-item__icon {
  fill: #7ab9e6;
}
.skills__item.react-native .skills-item__icon {
  fill: #e65fe0;
}
.skills__item.tailwind .skills-item__icon {
  fill: #5ebae1;
}
.skills__item.express .skills-item__icon {
  fill: #e7e8e9;
}
.skills__item.mongodb .skills-item__icon {
  fill: #198236;
}

.skills__item:hover .skills-item__icon,
.skills__item:hover .skills-item__icon path,
.skills__item:hover .skills-item__icon polygon,
.skills__item:hover .skills-item__icon rect {
  fill: var(--color-white);
}

.skills__item.nextjs:hover .skills-item__icon,
.skills__item.nextjs:hover .skills-item__icon g,
.skills__item.nextjs:hover .skills-item__icon path,
.skills__item.nextjs:hover .skills-item__icon polygon,
.skills__item.nextjs:hover .skills-item__icon rect {
  fill: var(--color-black);
}

.skills__item.nextjs:hover .skills-item__text span {
  color: var(--color-black-variant);
}
.skills__item.nextjs:hover .skills-item__text h3 {
  color: var(--color-black);
}
.skills__item.nextjs:hover .skills-item__level {
  color: var(--color-black-variant);
}

@media screen and (min-width: 480px) {
  .skills-item__text h3 {
    font-size: var(--font-lg);
  }

  .skills-group__grid {
    gap: 1.8rem;
  }
}

@media screen and (min-width: 586px) {
  .skills__group {
    padding: 2.4rem;
  }
}

@media screen and (min-width: 660px) {
  .skills-group__grid {
    gap: 2rem;
  }

  .skills__item {
    padding: 1.4rem;
  }

  .skills-item__text h3 {
    font-size: var(--font-lg);
  }

  .skills-item__level {
    bottom: 1.4rem;
    left: 1.4rem;
  }

  .skills-item__icon {
    bottom: 1.4rem;
    right: 1.4rem;
  }
}

@media screen and (min-width: 720px) {
  .skills__group {
    padding: 4rem;
  }

  .skills-group__grid {
    gap: 2.6rem;
  }

  .skills__item {
    padding: 1.8rem;
  }

  .skills-item__text h3 {
    font-size: var(--font-lg);
  }

  .skills-item__level {
    bottom: 1.8rem;
    left: 1.8rem;
  }

  .skills-item__icon {
    bottom: 1.8rem;
    right: 1.8rem;
  }
}

@media screen and (min-width: 820px) {
  .skills__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .skills__group {
    padding: 1.2rem;
  }

  .skills-group__grid {
    gap: 1rem;
  }

  .skills__item {
    padding: 0.8rem;
  }

  .skills-item__text h3 {
    font-size: var(--font-lg);
  }

  .skills-item__level {
    bottom: 0.8rem;
    left: 0.8rem;
  }

  .skills-item__icon {
    bottom: 0.8rem;
    right: 0.8rem;
  }
}

@media screen and (min-width: 992px) {
  .skills-container {
    padding-top: 7.6rem;
  }

  .skills-group__text h3 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1168px) {
  .skills__grid {
    gap: 2rem;
  }

  .skills__group {
    padding: 3rem;
  }

  .skills-group__grid {
    gap: 1.8rem;
  }

  .skills__item {
    padding: 1.2rem;
  }

  .skills-item__text h3 {
    font-size: var(--font-lg);
  }

  .skills-item__level {
    bottom: 1.2rem;
    left: 1.2rem;
  }

  .skills-item__icon {
    bottom: 1.2rem;
    right: 1.2rem;
  }
}

@media screen and (min-width: 1340px) {
  .skills__grid {
    gap: 5rem;
  }

  .skills__group {
    padding: 3.6rem;
  }

  .skills-group__grid {
    gap: 2.4rem;
  }

  .skills__item {
    padding: 1.6rem;
  }

  .skills-item__text h3 {
    font-size: var(--font-lg);
  }

  .skills-item__level {
    bottom: 1.6rem;
    left: 1.6rem;
  }

  .skills-item__icon {
    bottom: 1.6rem;
    right: 1.6rem;
  }
}
