.scroll-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  opacity: 0;
  transform: translateY(-12px);
  z-index: 9;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, background-color 0.4s ease-out;
}

.scroll-top.show {
  opacity: 1;
  transform: translateY(0);
}

.scroll-top:active {
  background-color: rgba(0, 0, 0, 0.8);
}

.scroll-top__icon {
  width: 24px;
  height: 24px;
  fill: var(--color-white);
}
