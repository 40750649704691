.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6.4rem;
}

.about {
  width: 95%;
  max-width: var(--container-width);
}

.about__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: justify;
  color: var(--color-gray);
  margin-bottom: 4rem;
}

.about__text span {
  font-size: var(--font-lg);
  font-weight: 500;
}

.about__text p {
  font-size: var(--font-sm);
  font-weight: 400;
}

.about__text a {
  font-weight: 600;
  color: var(--color-black);
}

.about__cards {
  display: grid;
  grid-template-columns: 100%;
  gap: 1rem;
}

.cards__item {
  display: flex;
  max-width: 480px;
  aspect-ratio: 2/1.2;
  background-color: var(--color-gray);
  border-radius: 1rem;
  position: relative;
  user-select: none;
  transition: all 0.2s ease-out;
}

.cards__item--medium {
  justify-self: end;
}

.cards__item--github {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 1.2rem;
  background: var(--gradient-github);
}

.cards__item--linkedin {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 1.2rem;
  background: var(--gradient-linkedin);
}

.cards__item--instagram {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 1.2rem;
  background: var(--gradient-instagram);
}

.cards-item__content {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  max-width: 75%;
}

.cards-item__content span {
  font-size: var(--font-xs);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: var(--color-gray-variant);
}

.cards-item__content h3 {
  font-size: var(--font-lg);
  font-weight: 500;
  color: var(--color-white);
  margin-left: -0.1rem;
}

.cards-item__icon {
  position: absolute;
  bottom: 1.2rem;
  right: 1.2rem;
  width: 24px;
  fill: var(--color-white);
}

.cards-item__account {
  position: absolute;
  bottom: 1.2rem;
  left: 1.2rem;
  font-size: var(--font-xs);
  font-weight: 400;
  color: var(--color-white);
  transition: all 0.2s ease-out 0.2s;
}

@media screen and (min-width: 480px) {
  .about__cards {
    gap: 4rem;
  }
}

@media screen and (min-width: 715px) {
  .about__cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .cards__item--medium {
    justify-self: start;
  }
}

@media screen and (min-width: 992px) {
  .about-container {
    padding-top: 7.6rem;
  }

  .about__cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 6rem;
  }
}

@media screen and (min-width: 1024px) {
  .cards-item__account {
    opacity: 0;
    transform: translateY(15px);
  }

  .cards__item::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    width: 105%;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    filter: blur(5px);
    opacity: 0;
    transition: all 0.2s ease-out 0.1s;
  }

  .cards__item:hover {
    transform: translateY(-10px) !important;
  }

  .cards__item:hover::after {
    opacity: 1;
    z-index: -1;
  }

  .cards__item:hover .cards-item__account {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (min-width: 1340px) {
  .about__cards {
    gap: 3rem;
  }

  .cards-item__content {
    max-width: 68%;
  }
}
