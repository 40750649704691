.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: url('../../assets/img/background.jpg') no-repeat center center;
  background-size: cover;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  transition: all 0.4s ease-in-out 0.2s;
}

.loader-container.closed {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black-translucid);
  animation: fadebg 0.3s ease-in;
}

@keyframes fadebg {
  0% {
    background-color: var(--color-black);
  }
  100% {
    background-color: var(--color-black-translucid);
  }
}

.loader__icon {
  width: 48px;
  height: 48px;
  background: var(--color-white);
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  animation: pulse 1s ease-in infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.loader__message {
  position: absolute;
  bottom: 1.4rem;
  left: 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  animation: fadeup 0.4s ease-out;
  transition: all 0.3s ease-out;
}

@keyframes fadeup {
  0% {
    transform: translateY(35px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.loader-container.closed .loader__message {
  transform: translateY(-50px);
  opacity: 0;
}

.message__subtitle {
  font-size: var(--font-xs);
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: var(--color-white);
  transition: all 0.2s ease-out;
}

.message__title {
  font-size: var(--font-xl);
  font-weight: 700;
  letter-spacing: -2px;
  color: var(--color-white);
  transition: all 0.2s ease-out;
}

.navbar-container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0;
  transition: all 0.2s ease-out;
}

.navbar-container.navbar-container--loader {
  background-color: transparent;
}

.navbar {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar__left-item {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  animation: fade 0.4s ease-in;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.left-item__title {
  font-size: var(--font-lg);
  font-weight: 700;
  color: var(--color-black);
}

.left-item__subtitle {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--color-black);
}

.left-item__title--loader,
.left-item__subtitle--loader {
  color: var(--color-gray) !important;
}

@media screen and (min-width: 768px) {
  .navbar-container {
    padding: 1.4rem 1rem;
  }

  .loader__message {
    left: 2.6rem;
    bottom: 2.6rem;
  }

  .message__subtitle {
    font-size: var(--font-sm);
  }

  .message__title {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .navbar-container {
    padding: 2rem 1.4rem;
  }

  .loader__message {
    left: 3rem;
    bottom: 3rem;
  }

  .message__subtitle {
    font-size: var(--font-sm);
  }

  .message__title {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1760px) {
  .loader__message {
    left: 4rem;
    bottom: 4rem;
  }
}
