.banner-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.banner {
  width: 95%;
  max-width: var(--container-width);
  height: 658px;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.banner__background-text {
  width: 100%;
  position: absolute;
  top: 280px;
  left: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.banner__background-text span {
  font-size: 5.6rem;
  font-weight: 800;
  color: var(--color-white-variant);
  opacity: 1;
  text-shadow: 6px 6px 2rem rgba(0, 0, 0, 0.08);
  margin-left: -0.8rem;
}

.banner__content {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.content__subtitle {
  font-size: var(--font-xs);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: var(--color-gray);
}

.content__title {
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -2.2px;
  color: var(--color-black);
  margin-bottom: 1rem;
  margin-left: -0.2rem;
}

.content__text {
  font-size: var(--font-sm);
  font-weight: 500;
  color: var(--color-gray);
  margin-bottom: 1rem;
}

.content__buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.buttons__item {
  font-size: var(--font-sm);
  font-weight: 700;
  padding-bottom: 1px;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-out;
}

.buttons__separator {
  font-size: 0.9rem;
  color: var(--color-gray);
}

.banner__image {
  position: absolute;
  top: 64px;
  left: 75px;
  z-index: -1;
  transition: all 0.2s ease-out;
}

.banner__image img {
  width: 440px;
}

.banner__scroll-down {
  width: 18px;
  height: 30px;
  border: 2px solid var(--color-black);
  border-radius: 99px;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  padding-top: 7px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateY(4rem);
  transition: transform 0.4s ease-out 1s, opacity 0.4s ease-out 1.1s, border-color 0.3s ease-out;
}

.banner__scroll-down div {
  width: 3px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-black);
  animation: scrollDown 2s infinite;
  transition: background-color 0.3s ease-out;
}

@keyframes scrollDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (min-width: 410px) {
  .banner__image {
    top: 82px;
  }
}

@media screen and (min-width: 568px) {
  .banner {
    height: 580px;
    align-items: center;
  }

  .banner__content {
    max-width: 60%;
  }

  .banner__image {
    position: absolute;
    left: auto;
    right: -110px;
    top: calc(50% - 200px);
  }

  .banner__image img {
    width: 400px;
  }
}

@media screen and (min-width: 768px) {
  .banner-container {
    padding: 0 1rem;
  }

  .banner {
    height: 100vh;
    align-items: flex-end;
  }

  .banner__content {
    margin-bottom: 6rem;
    max-width: 62%;
  }

  .content__title {
    font-size: var(--font-xxl);
  }

  .content__text {
    font-size: var(--font-md);
  }

  .buttons__item {
    font-size: var(--font-md);
  }

  .buttons__separator {
    font-size: var(--font-sm);
  }

  .banner__image {
    bottom: 25px;
    top: auto;
    right: -380px;
  }

  .banner__image img {
    height: 90vh;
    width: auto;
  }

  .banner__scroll-down {
    display: flex;
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
}

@media screen and (min-width: 1024px) {
  .banner-container {
    padding: 0 1.4rem;
  }

  .buttons__item:hover {
    border-bottom-color: var(--color-black);
  }

  .banner__scroll-down:hover {
    border-color: var(--color-gray);
  }

  .banner__scroll-down:hover div {
    background-color: var(--color-gray);
  }

  .banner__image {
    bottom: 0;
  }
}

@media screen and (min-width: 1136px) {
  .banner__image {
    right: -320px;
  }
}

@media screen and (min-width: 1280px) {
  .banner__content {
    max-width: 50%;
  }

  .banner__image {
    right: -200px;
  }
}

@media screen and (min-width: 1440px) {
  .banner__background-text {
    visibility: visible;
    opacity: 1;
  }

  .banner__image {
    bottom: 0;
    right: -220px;
  }

  .banner__image img {
    height: 90vh;
  }
}

@media screen and (min-width: 1660px) {
  .banner__image {
    right: -220px;
  }
}

@media screen and (min-width: 1820px) {
  .banner__image {
    right: -300px;
  }
}

@media screen and (min-width: 768px) and (max-height: 900px) {
  .banner__background-text {
    top: 200px;
  }

  .banner__image {
    right: -285px;
  }
}

@media screen and (min-width: 1280px) and (max-height: 885px) {
  .banner__image {
    right: -120px;
  }
}

@media screen and (min-width: 1820px) and (max-height: 1090px) {
  .banner__image {
    right: -200px;
  }
}
