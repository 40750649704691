.navbar-container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0;
  background-color: var(--color-white-translucid);
  transition: all 0.2s ease-out;
}

.navbar {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.navbar__left-item {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.left-item__title {
  font-size: var(--font-lg);
  font-weight: 700;
  color: var(--color-black);
}

.left-item__subtitle {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--color-black);
}

.navbar__right-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.right-item__title {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: var(--color-black);
  transition: all 0.2s ease-out;
}

.right-item__icon {
  width: 24px;
  height: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.right-item__icon span {
  width: 100%;
  height: 3px;
  background-color: var(--color-black);
  transition: all 0.2s ease-out;
}

.right-item__icon.open span:nth-child(1) {
  transform: rotate(45deg) translateY(8.8px);
}

.right-item__icon.open span:nth-child(2) {
  transform: translateX(-35px) scale(0);
  opacity: 0;
}

.right-item__icon.open span:nth-child(3) {
  transform: rotate(-45deg) translateY(-8.8px);
}

.drawer-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: url('../../assets/img/background.jpg') no-repeat center center;
  background-size: cover;
  overflow: hidden;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  transition: all 0.4s ease-in-out;
}

.drawer-container.open {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.drawer-container.closing {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.drawer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black-translucid);
}

.drawer .navbar-container {
  background-color: transparent;
}

.left-item__title--drawer,
.left-item__subtitle--drawer,
.right-item__title--drawer {
  color: var(--color-gray);
}

.right-item__icon--drawer span {
  background-color: var(--color-gray);
}

.right-item__icon--drawer span:nth-child(1) {
  transform: rotate(45deg) translateY(8.8px);
}

.right-item__icon--drawer span:nth-child(2) {
  transform: rotate(-45deg) translateY(-8.8px);
}

.drawer__menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
}

.menu__title {
  font-size: var(--font-sm);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: var(--color-gray);
}

.menu__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.8rem;
}

.list__item {
  font-size: var(--font-xl);
  font-weight: 700;
  letter-spacing: -2px;
  color: var(--color-gray);
  transition: all 0.3s ease-out;
}

.menu__social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.social__item {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-white);
  transition: all 0.2s ease-out;
}

.item__icon {
  width: 14px;
  fill: var(--color-black);
  transition: all 0.2s ease-out 0.4s;
}

.drawer-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 0;
}

.footer__text {
  font-size: var(--font-xs);
  font-weight: 400;
  color: var(--color-gray);
}

@media screen and (min-width: 768px) {
  .navbar-container {
    padding: 1.4rem 1rem;
  }

  .list__item {
    font-size: 3.2rem;
  }

  .social__item {
    width: 32px;
    height: 32px;
  }

  .item__icon {
    width: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .navbar-container {
    padding: 2rem 1.4rem;
  }

  .navbar__right-item:hover .right-item__title {
    color: var(--color-gray);
  }

  .navbar__right-item:hover .right-item__icon span {
    background-color: var(--color-gray);
  }

  .navbar__right-item:hover .right-item__title--drawer {
    color: var(--color-white);
  }

  .navbar__right-item:hover .right-item__icon--drawer span {
    background-color: var(--color-white);
  }

  .list__item {
    font-size: 4rem;
  }

  .list__item:hover {
    color: var(--color-white);
  }

  .social__item {
    width: 36px;
    height: 36px;
  }

  .social__item:hover {
    transform: translateY(-3px);
  }

  .item__icon {
    width: 18px;
  }
}

@media screen and (min-width: 1820px) {
  .navbar-container {
    background-color: transparent;
  }

  .navbar {
    width: 98%;
  }
}
