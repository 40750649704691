.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6.4rem;
  padding-bottom: 4rem;
  margin-top: 2rem;
}

.footer {
  width: 95%;
  max-width: var(--container-width);
  border-top: 1px solid var(--color-gray-variant);
  padding-top: 4rem;
  padding-bottom: 8rem;
  position: relative;
}

.footer__grid {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 3.2rem;
}

.footer-grid__item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-grid__item h3 {
  font-size: var(--font-md);
  font-weight: 700;
  color: var(--color-black);
}

.footer-grid__item p {
  font-size: var(--font-sm);
  font-weight: 400;
  color: var(--color-gray);
}

.footer-grid-item__data {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.footer-grid-item__data div {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.footer-grid-item__data div span:nth-child(1) {
  font-size: var(--font-sm);
  font-weight: 500;
  color: var(--color-gray);
}

.footer-grid-item__data div span:nth-child(2) {
  font-size: var(--font-sm);
  font-weight: 500;
  color: var(--color-black);
}

.footer-grid-item__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.4rem;
}

.footer-grid-item__list a {
  font-size: var(--font-sm);
  font-weight: 500;
  color: var(--color-black);
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-out;
}

.footer__brand {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.footer__brand > .left-item__title {
  color: var(--color-gray-variant);
}

.footer__brand > .left-item__subtitle {
  color: var(--color-gray-variant);
}

.footer__copyright {
  position: absolute;
  bottom: 12px;
  right: 0;
  color: var(--color-gray-variant);
}

@media screen and (min-width: 768px) {
  .footer__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .footer-container {
    padding-top: 7.6rem;
  }
}

@media screen and (min-width: 1024px) {
  .footer-container {
    margin-top: 6rem;
  }

  .footer__grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 5rem;
  }

  .footer-grid-item__list {
    grid-template-columns: 100%;
  }

  .footer-grid-item__list a:hover {
    border-bottom-color: var(--color-black);
  }

  .footer__copyright {
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
  }
}

@media screen and (min-width: 1280px) {
  .footer__grid {
    grid-template-columns: 40% 15% 15% 15%;
    gap: 4rem;
  }

  .footer-grid__item h3 {
    font-size: var(--font-lg);
  }
}
