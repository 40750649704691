.projects-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5.4rem;
}

.projects {
  width: 95%;
  max-width: var(--container-width);
}

.projects__grid {
  display: grid;
  grid-template-columns: 100%;
  gap: 1.8rem;
}

.project {
  display: flex;
  position: relative;
  height: 140vw;
  padding: 1.6rem;
  overflow: hidden;
  user-select: none;
}

.project__info {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  max-width: 75%;
}

.project__info span {
  font-size: var(--font-xs);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: var(--color-gray-variant);
}

.project__info h3 {
  font-size: var(--font-lg);
  font-weight: 700;
  letter-spacing: -1px;
  color: var(--color-white);
  margin-left: -0.1rem;
}

.project__info--dark span {
  color: var(--color-gray);
}

.project__info--dark h3 {
  color: var(--color-black);
}

.project__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-out;
}

.project:hover .project__img {
  transform: scale(1.04);
}

.project__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1.6rem;
  background-color: var(--color-black-translucid);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-out;
}

.project:hover .project__overlay {
  opacity: 1;
  pointer-events: all;
}

.overlay__info {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  max-width: 75%;
}

.overlay__info > span {
  font-size: var(--font-xs);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: var(--color-gray);
  opacity: 0;
  transform: translateY(25px);
  transition: all 0.3s ease-out;
}

.project:hover .overlay__info > span {
  opacity: 1;
  transform: translateY(0);
}

.overlay__info h3 {
  font-size: var(--font-lg);
  font-weight: 700;
  letter-spacing: -1px;
  color: var(--color-white);
  margin-left: -0.1rem;
  margin-bottom: 0.6rem;
  opacity: 0;
  transform: translateY(25px);
  transition: all 0.3s ease-out 0.12s;
}

.overlay__info p {
  font-size: var(--font-xs);
  font-weight: 400;
  color: var(--color-gray);
  margin-bottom: 0.6rem;
  opacity: 0;
  transform: translateY(25px);
  transition: all 0.3s ease-out 0.24s;
}

.project:hover .overlay__info h3 {
  opacity: 1;
  transform: translateY(0);
}

.project:hover .overlay__info p {
  opacity: 1;
  transform: translateY(0);
}

.info__links {
  display: flex;
  align-items: center;
  gap: 1.8rem;
  opacity: 0;
  transform: translateY(25px);
  transition: all 0.3s ease-out 0.36s;
}

.project:hover .info__links {
  opacity: 1;
  transform: translateY(0);
}

.link-item {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.link-item span {
  font-size: var(--font-xs);
  font-weight: 600;
  color: var(--color-gray);
  transition: all 0.2s ease-out;
}

.link-item__icon {
  width: 12px;
  fill: var(--color-gray);
  transition: all 0.2s ease-out;
}

@media screen and (min-width: 568px) {
  .projects-container {
    padding-top: 0;
  }
}

@media screen and (min-width: 660px) {
  .projects__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .project {
    height: 62vw;
  }
}

@media screen and (min-width: 768px) {
  .projects-container {
    padding-top: 10rem;
  }

  .projects__grid {
    gap: 1.8rem;
  }

  .project {
    padding: 2rem;
  }

  .project__info {
    max-width: 85%;
  }

  .project__info h3 {
    font-size: 1.8rem;
  }

  .project__overlay {
    padding: 2rem;
  }

  .overlay__info {
    max-width: 85%;
  }

  .overlay__info h3 {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .project__info {
    max-width: 60%;
  }

  .overlay__info {
    max-width: 60%;
  }

  .overlay__info p {
    width: 150%;
  }

  .link-item:hover span {
    color: var(--color-white);
  }

  .link-item:hover .link-item__icon {
    transform: translateX(4px);
    fill: var(--color-white);
  }
}

@media screen and (min-width: 1280px) {
  .projects__grid {
    gap: 3.2rem;
  }

  .project {
    height: 58vw;
    max-height: 880px;
    padding: 2.6rem;
  }

  .project__info > span {
    font-weight: 500;
  }

  .project__overlay {
    padding: 2.6rem;
  }

  .overlay__info > span {
    font-weight: 500;
  }

  .link-item span {
    font-size: 0.9rem;
  }

  .link-item__icon {
    width: 14px;
  }
}
