.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6.4rem;
}

.contact {
  width: 95%;
  max-width: var(--container-width);
}

.contact__text {
  font-size: var(--font-sm);
  font-weight: 400;
  color: var(--color-gray);
  text-align: justify;
  margin-bottom: 4rem;
}

.contact__grid {
  display: grid;
  grid-template-columns: 100%;
  gap: 2.8rem;
}

.contact-grid__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.grid-cards__item {
  aspect-ratio: 1/1;
  position: relative;
  display: flex;
  border-radius: 12px;
  padding: 1rem;
  user-select: none;
  transition: all 0.3s ease-out;
}

.grid-cards__item.whatsapp {
  background: var(--gradient-whatsapp);
}
.grid-cards__item.linkedin {
  background: var(--gradient-linkedin-variant);
}

.cards-item__text {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.cards-item__text span {
  font-size: var(--font-xs);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: var(--color-white);
}

.cards-item__text h3 {
  font-size: var(--font-lg);
  font-weight: 700;
  letter-spacing: -1px;
  color: var(--color-white);
  margin-left: -0.1rem;
}

.cards-item__account-contact {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  font-size: var(--font-xs);
  font-weight: 400;
  color: var(--color-white);
}

.cards-item__icon-contact {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 30px;
  height: 30px;
  fill: var(--color-white);
}

.contact-grid__form form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  user-select: none;
}

.form__header {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.form-header__subtitle {
  font-size: var(--font-xs);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: var(--color-gray-variant);
}

.form-header__title {
  font-size: var(--font-lg);
  font-weight: 700;
  letter-spacing: -1px;
  color: var(--color-gray);
  margin-left: -0.1rem;
}

.form__input-group {
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  margin-bottom: 0.4rem;
}

.form__input-field {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.input-group__label {
  font-size: var(--font-xs);
  font-weight: 400;
  color: var(--color-gray);
}

.input-group__input {
  width: 100%;
  font-size: var(--font-sm);
  font-weight: 500;
  color: var(--color-black);
  border-bottom: 1px solid var(--color-gray-variant);
  padding: 8px 0;
  transition: all 0.2s ease-out;
}

.input-group__input::placeholder {
  color: var(--color-black);
  transition: all 0.2s ease-out;
}

.input-group__input.error {
  border-bottom-color: var(--color-error);
}
.input-group__input.error::placeholder {
  color: var(--color-error);
}

.input-group__input:focus {
  border-bottom-color: var(--color-black);
}

textarea.input-group__input {
  min-height: 10rem;
}

.form__submit-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__info {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  opacity: 0;
  transform: translateX(-25px);
  pointer-events: none;
  transition: all 0.2s ease-out;
}
.form__info.show {
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
}

.form-info__icon {
  width: 16px;
  height: 16px;
}
.form-info__icon.error {
  fill: var(--color-error);
}
.form-info__icon.success {
  fill: var(--color-success);
}

.form-info__message {
  font-size: var(--font-xs);
  font-weight: 600;
  color: var(--color-error);
}
.form-info__message.error {
  color: var(--color-error);
}
.form-info__message.loading {
  color: var(--color-loading);
}
.form-info__message.success {
  color: var(--color-success);
}

.form-info__loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid var(--color-loading);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form__submit-btn {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding-bottom: 1px;
  margin-left: 0.2rem;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-out;
}

.submit-btn__icon {
  width: 18px;
  height: 18px;
  fill: var(--color-black);
  transition: all 0.2s ease-out;
}

.form__submit-btn:disabled .submit-btn__icon {
  fill: var(--color-gray-variant);
}

.form__submit-btn span {
  font-size: var(--font-sm);
  font-weight: 700;
  color: var(--color-black);
  transition: all 0.2s ease-out;
}

.form__submit-btn:disabled span {
  color: var(--color-gray-variant);
}

@media screen and (min-width: 768px) {
  .contact__grid {
    grid-template-columns: 25% auto;
  }

  .contact-grid__cards {
    display: flex;
    flex-direction: column;
    max-width: 290px;
  }

  .form__submit-container {
    flex-direction: row-reverse;
  }

  .form__submit-btn {
    margin-left: 0;
    margin-right: 0.2rem;
  }
}

@media screen and (min-width: 992px) {
  .contact-container {
    padding-top: 7.6rem;
  }
}

@media screen and (min-width: 1024px) {
  .grid-cards__item:hover {
    filter: brightness(1.05);
  }

  .form__submit-btn:hover {
    border-bottom-color: var(--color-black);
  }
}

@media screen and (min-width: 1280px) {
  .form__header {
    margin-bottom: 1.8rem;
  }
}

@media screen and (min-width: 1380px) {
  .contact__grid {
    gap: 0;
  }

  textarea.input-group__input {
    min-height: 12rem;
  }
}
