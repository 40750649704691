@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Open Sans', sans-serif;
  --font-xxl: 3.4rem;
  --font-xl: 2.4rem;
  --font-lg: 1.4rem;
  --font-md: 1.2rem;
  --font-sm: 1rem;
  --font-xs: 0.8rem;

  --container-width: 1400px;

  --color-black: #0f141e;
  --color-black-variant: #20242c;
  --color-black-translucid: rgba(15, 20, 30, 0.96);
  --color-white: #ffffff;
  --color-white-variant: #fcfcfc;
  --color-white-translucid: rgba(255, 255, 255, 0.96);
  --color-gray: #878a8f;
  --color-gray-variant: #e7e8e9;
  --color-error: #d81e5b;
  --color-loading: #1e90ff;
  --color-success: #00b894;

  --gradient-github: radial-gradient(100% 100% at 49.99% 0%, #333333 0%, #575757 100%);
  --gradient-linkedin: radial-gradient(123.96% 188.8% at 78.81% 0%, #0e76a8 0%, #7bd4ff 100%);
  --gradient-linkedin-variant: radial-gradient(100% 100% at 12.3% 0%, #99cef5 0%, #4693cb 100%);
  --gradient-instagram: radial-gradient(
    95.64% 135.74% at 16.27% 106.23%,
    #feda77 0%,
    #f58529 20.62%,
    #dd2a7b 53.43%,
    #8134af 78.95%,
    #515bd4 100%
  );
  --gradient-whatsapp: radial-gradient(100% 100% at 11.76% 0%, #9cebad 0%, #57bb6d 100%);
  --gradient-react: radial-gradient(100% 100% at 10.62% 0%, #80bde9 0%, #236b9e 100%);
  --gradient-react-native: radial-gradient(100% 100% at 6.56% 0%, #ff8cfa 0%, #e65fe0 100%);
  --gradient-javascript: radial-gradient(100.11% 100.11% at 3.94% 0.02%, #ebda6e 0%, #f0db4f 100%);
  --gradient-html: radial-gradient(100% 100% at 7.5% 0%, #dd5531 0%, #ff8257 100%);
  --gradient-css: radial-gradient(100% 100% at 4.79% 0%, #1f62ae 0%, #347dc6 100%);
  --gradient-tailwind: radial-gradient(99.06% 99.06% at 6.56% 0.21%, #99c3d5 0%, #5ebae1 100%);
  --gradient-nodejs: radial-gradient(100% 100% at 6.88% 0%, #8dcc7f 0%, #539e43 100%);
  --gradient-express: radial-gradient(100.11% 100.11% at 10.9% -0.02%, #5a5a5a 0%, #3a3a3a 100%);
  --gradient-mongodb: radial-gradient(100% 100% at 7.5% -0.21%, #198236 0%, #025f1b 100%);
  --gradient-firebase: radial-gradient(100% 100% at 7.92% 0%, #ffca28 0%, #f57f17 100%);
  --gradient-jwt: radial-gradient(99.78% 99.78% at 6.22% 0.06%, #f50057 8.9%, #d500f9 38.08%, #29b6f6 100%);
  --gradient-nextjs: radial-gradient(99.78% 99.78% at 6.22% 0.06%, #ffffff 0%, #e3e3e3 100%);
  --gradient-python: radial-gradient(99.78% 99.78% at 6.22% 0.06%, #165eb0 0%, #3671ac 100%);
  --gradient-aws: radial-gradient(99.78% 99.78% at 6.22% 0.06%, #e7af54 0%, #e79d25 100%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  position: relative;
  background-color: var(--color-white);
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  border: none;
  outline: none;
}

select {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

textarea {
  border: none;
  outline: none;
  resize: none;
}

label {
  cursor: pointer;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--color-white);
}

::-webkit-scrollbar-thumb {
  background: var(--color-gray);
  border-radius: 100px;
}

::selection {
  background-color: var(--color-gray);
  color: var(--color-white);
}

::-moz-selection {
  background-color: var(--color-gray);
  color: var(--color-white);
}

.body {
  overflow-x: hidden;
}

.section-header {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-bottom: 3.2rem;
}

.header__subtitle {
  font-size: var(--font-xs);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: var(--color-gray);
}

.header__title {
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -2.2px;
  color: var(--color-black);
  margin-bottom: 1rem;
  margin-left: -0.2rem;
}

@media screen and (min-width: 768px) {
  .header__title {
    font-size: var(--font-xxl);
  }
}
